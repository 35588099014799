import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';
import {UploadingOrderFileModel} from '../../../../shared/models/uploading-order-file-model';

enum UploadingFilesSubjectAction {
    SET_DATA_UPLOADING_ORDER_FILE = 'SetDataUploadingOrderFile',
    RESET_DATA_UPLOADING_ORDER_FILE = 'ResetDataUploadingOrderFile'
}

export const uploadFIleSubjectAction = createActionGroup({
    source: StoreFeatureNames.UPLOADING_ORDER_FILE,
    events: {
        [UploadingFilesSubjectAction.SET_DATA_UPLOADING_ORDER_FILE]: props<{ data: UploadingOrderFileModel }>(),
        [UploadingFilesSubjectAction.RESET_DATA_UPLOADING_ORDER_FILE]: props<{ data: UploadingOrderFileModel }>()
    }
});
