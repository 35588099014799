import {createReducer, on} from '@ngrx/store';
import {uploadFIleSubjectAction} from '../action/uploading-files-subject.action';
import {UploadingOrderFileModel} from '../../../../shared/models/uploading-order-file-model';

export const uploadOrderFileReducer = createReducer(
    {},
    on(uploadFIleSubjectAction.setDataUploadingOrderFile, (state: UploadingOrderFileModel, {data}) => ({
        ...state,
        data
    })),
    on(uploadFIleSubjectAction.resetDataUploadingOrderFile, (_state: UploadingOrderFileModel, {}) => ({}))
);
