import {createReducer, on} from '@ngrx/store';
import {accountDataActions} from '../action/account-data-action';
import {IAccountDataStore} from '../interfaces/IPressUpStore';

export const accountDataReducer = createReducer(
    {},
    on(accountDataActions.setName, (state: IAccountDataStore, {name}) => ({
        ...state,
        name
    })),
    on(accountDataActions.setSurname, (state: IAccountDataStore, {surname}) => ({
        ...state,
        surname
    })),
    on(accountDataActions.setPhone, (state: IAccountDataStore, {phone}) => ({
        ...state,
        phone
    })),
    on(accountDataActions.setSociety, (state: IAccountDataStore, {society}) => ({
        ...state,
        society
    })),
    on(accountDataActions.setSector, (state: IAccountDataStore, {sector}) => ({
        ...state,
        sector
    })),
    on(accountDataActions.setVatNumber, (state: IAccountDataStore, {vatNumber}) => ({
        ...state,
        vatNumber
    })),
    on(accountDataActions.setTaxIdCode, (state: IAccountDataStore, {taxIDCode}) => ({
        ...state,
        taxIDCode
    })),
    on(accountDataActions.setBusinessName, (state: IAccountDataStore, {businessName}) => ({
        ...state,
        businessName
    })),
    on(accountDataActions.setPec, (state: IAccountDataStore, {pec}) => ({
        ...state,
        pec
    })),
    on(accountDataActions.setUniqueCode, (state: IAccountDataStore, {uniqueCode}) => ({
        ...state,
        uniqueCode
    })),
    on(accountDataActions.setCountry, (state: IAccountDataStore, {country}) => ({
        ...state,
        country
    })),
    on(accountDataActions.setCounty, (state: IAccountDataStore, {county}) => ({
        ...state,
        county
    })),
    on(accountDataActions.setCap, (state: IAccountDataStore, {cap}) => ({
        ...state,
        cap
    })),
    on(accountDataActions.setLocation, (state: IAccountDataStore, {location}) => ({
        ...state,
        location
    })),
    on(accountDataActions.setAddress, (state: IAccountDataStore, {address}) => ({
        ...state,
        address
    })),
    on(accountDataActions.setDeliveryCountry, (state: IAccountDataStore, {deliveryCountry}) => ({
        ...state,
        deliveryCountry
    })),
    on(accountDataActions.setDeliveryCounty, (state: IAccountDataStore, {deliveryCounty}) => ({
        ...state,
        deliveryCounty
    })),
    on(accountDataActions.setDeliveryCap, (state: IAccountDataStore, {deliveryCap}) => ({
        ...state,
        deliveryCap
    })),
    on(accountDataActions.setDeliveryLocation, (state: IAccountDataStore, {deliveryLocation}) => ({
        ...state,
        deliveryLocation
    })),
    on(accountDataActions.setDeliveryAddress, (state: IAccountDataStore, {deliveryAddress}) => ({
        ...state,
        deliveryAddress
    })),
    on(accountDataActions.setHowKnowUs, (state: IAccountDataStore, {howKnowUs}) => ({
        ...state,
        howKnowUs
    })),
);
