import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';

enum AccountDataActionType {
    SET_NAME = 'SetName',
    SET_SURNAME = 'SetSurname',
    SET_PHONE = 'SetPhone',
    SET_SOCIETY = 'SetSociety',
    SET_SECTOR = 'SetSector',
    SET_VAT_NUMBER = 'SetVatNumber',
    SET_TAX_ID_CODE = 'SetTaxIdCode',
    SET_BUSINESS_NAME = 'SetBusinessName',
    SET_PEC = 'SetPec',
    SET_UNIQUE_CODE = 'SetUniqueCode',
    SET_COUNTRY = 'SetCountry',
    SET_COUNTY = 'SetCounty',
    SET_CAP = 'SetCap',
    SET_LOCATION = 'SetLocation',
    SET_ADDRESS = 'SetAddress',
    SET_DELIVERY_COUNTRY = 'SetDeliveryCountry',
    SET_DELIVERY_COUNTY = 'SetDeliveryCounty',
    SET_DELIVERY_CAP = 'SetDeliveryCap',
    SET_DELIVERY_LOCATION = 'SetDeliveryLocation',
    SET_DELIVERY_ADDRESS = 'SetDeliveryAddress',
    SET_HOW_KNOW_US = 'SetHowKnowUs',
}

export const accountDataActions = createActionGroup({
    source: StoreFeatureNames.ACCOUNT_DATA,
    events: {
        [AccountDataActionType.SET_NAME]: props<{ name: string }>(),
        [AccountDataActionType.SET_SURNAME]: props<{ surname: string }>(),
        [AccountDataActionType.SET_PHONE]: props<{ phone: string }>(),
        [AccountDataActionType.SET_SOCIETY]: props<{ society: number }>(),
        [AccountDataActionType.SET_SECTOR]: props<{ sector: number }>(),
        [AccountDataActionType.SET_VAT_NUMBER]: props<{ vatNumber: string }>(),
        [AccountDataActionType.SET_TAX_ID_CODE]: props<{ taxIDCode: string }>(),
        [AccountDataActionType.SET_BUSINESS_NAME]: props<{ businessName: string }>(),
        [AccountDataActionType.SET_PEC]: props<{ pec: string }>(),
        [AccountDataActionType.SET_UNIQUE_CODE]: props<{ uniqueCode: string }>(),
        [AccountDataActionType.SET_COUNTRY]: props<{ country: string }>(),
        [AccountDataActionType.SET_COUNTY]: props<{ county: string }>(),
        [AccountDataActionType.SET_CAP]: props<{ cap: string }>(),
        [AccountDataActionType.SET_LOCATION]: props<{ location: string }>(),
        [AccountDataActionType.SET_ADDRESS]: props<{ address: string }>(),
        [AccountDataActionType.SET_DELIVERY_COUNTRY]: props<{ deliveryCountry: string }>(),
        [AccountDataActionType.SET_DELIVERY_COUNTY]: props<{ deliveryCounty: string }>(),
        [AccountDataActionType.SET_DELIVERY_CAP]: props<{ deliveryCap: string }>(),
        [AccountDataActionType.SET_DELIVERY_LOCATION]: props<{ deliveryLocation: string }>(),
        [AccountDataActionType.SET_DELIVERY_ADDRESS]: props<{ deliveryAddress: string }>(),
        [AccountDataActionType.SET_HOW_KNOW_US]: props<{ howKnowUs: number }>(),
    }
});
